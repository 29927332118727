import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Space, Card, Spin, Popover, Button, Divider} from "antd";
import {observable} from "mobx";
import User from "./User";
import {callWithCatch, pp} from "./utils";

import actioncable from 'actioncable';
import BotIcon from "./BotIcon";
import Device from "./Device";

let prefix = import.meta.env.DEV ? "ws://localhost:3007" : "wss://www.dinrega.com";

export const SearchBotStore = observable({
  store: null,
  status: null,
  uuid: null,
  close: () => {
    SearchBotStore.status = null;
    SearchBotStore.uuid = null;
  },
  open: async (store, uuid) => {
    SearchBotStore.uuid = uuid;
    SearchBotStore.store = store;
    SearchBotStore.status = { prep: true };

    const cable = actioncable.createConsumer(`${prefix}/cable`)
    cable.subscriptions.create({ channel: "AdvSearchsChannel", uuid }, {
      received(data) {
        // only until store has changed
        if (SearchBotStore.uuid === uuid)
          SearchBotStore.status = data;
      }
    });
  }
});

let TextPart = ({text, sup, hint}) => {
  if (text)
    return <span>{text}</span>

  return <Popover
    content={hint}
    title="להמשך קריאה"
    overlayInnerStyle={{width: "300px"}}
    placement="right"
  >
    <sup className="bg-slate-400 text-white rounded cursor-pointer" style={{padding: "0 2px", marginLeft: "2px"}}>{sup}</sup>
  </Popover>
}

export const SearchBotSummary = observer(({part}) => {
  let status = SearchBotStore.status;

  if (!status)
    return null;

  let title = <Space>
    {status.status !== "done" && <Spin />}
  </Space>

  let body = status.text;

  if (status.summary)
    body = <div className="space-y-4">
      <div>{status.summary}</div>
      {status.issues?.map((issue, i) => <div className="rounded-none pb-2 bg-white" key={i}>
        <div>{issue.name}</div>
        <div>{issue.summary.map((s, j) => <TextPart {...s} key={j} />)}</div>
        {/*<div className="text-sm hover-child"><Space split={<Divider type="vertical" />}><a>העתק פסקה</a><a>הצע חיפושי המשך</a><a>נסח טענות</a></Space></div>*/}
      </div>)}
    </div>

  return <div style={{ whiteSpace: "pre-line", minHeight: "80vh"}}>
    <span>{title}</span>
    <span>{body}</span>
    <br/>
    <br/>
    {status.status === "done" && <div style={{fontSize: "10px", color: "#9CA3AF"}}>סיכום הAI בבטא ויכול לעשות טעויות. אנא ודאו את תשובותיו עם הפסיקה.</div>}
  </div>
})
