import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {ExceptionOutlined, FileSyncOutlined, ContainerOutlined, RotateRightOutlined} from "@ant-design/icons";
import MyDrawer from "./MyDrawer";
import {observable} from "mobx";
import User from "./User";
import {Skeleton} from "antd";
import Api from "./Api";
import {addRoute} from "./Router";
import Device from "./Device";
import HtmlDiv from "./HtmlDiv";
import ArticleStore from "./ArticleStore";

export const LawDrawerStore = observable({
  law: null,
  opened: false,
  locate: null,

  load: async (id, locate) => {
    User.track('law.open')
    // addRoute({aid: id},null, "/docs/" + id)
    LawDrawerStore.locate = locate;
    LawDrawerStore.opened = true;

    let data = (await Api.get("/laws/" + id)).data;

    LawDrawerStore.law = data.law;
  },

  close: () => {
    LawDrawerStore.locate = null;
    LawDrawerStore.opened = false;
    LawDrawerStore.law = null;
    addRoute({}, null, "/");
  }
});

export const LawDrawer = observer(({}) => {
  let law = LawDrawerStore.law;

  useEffect(() => {
    let {locate} = LawDrawerStore;

    setTimeout(() => {
      if (!locate || !locate.length)
        return;

      let lw = toWords(locate);

      let els = _.map(document.querySelectorAll('.law-content1, .law-content2, .law-content3, .law-content4, .law-content5'), el => [el, toWords(el.innerText)]);

      let result = _.maxBy(els, el => _.intersection(lw, el[1]).length)

      if (result) {
        result[0].classList.add("line-selected");
        result[0].scrollIntoView({ behavior: 'instant', block: 'center' });
      }
    }, 32)
  }, [law]);

  if (!LawDrawerStore.opened)
    return null;

  let inner = <Skeleton active />;
  let header;

  let toWords = (s) => _.filter(s.split(/\s+/g), s => s.length > 3)

  if (law) {
    header = <div className="truncate-text" style={{width: "100%"}}>
      <div><b>{law && law.title}</b></div>
    </div>

    inner = <div className="law-drawer" style={{minHeight: "100vh"}}>
      <HtmlDiv html={law.html} />
      <br/>
      <hr />
      <ul id="law-footer-info">
        <li>הטקסט לקוח <a target="_blank" href="https://he.wikisource.org/wiki/%D7%A1%D7%A4%D7%A8_%D7%94%D7%97%D7%95%D7%A7%D7%99%D7%9D_%D7%94%D7%A4%D7%AA%D7%95%D7%97">מספר החוקים הפתוח</a></li>
        <li>הטקסט מוגש בכפוף לרישיון <a target="_blank" href="https://creativecommons.org/licenses/by-sa/3.0/deed.he">Creative Commons ייחוס-שיתוף זהה 3.0</a>;
          ייתכן שישנם תנאים נוספים.
          ר' את <a target="_blank" href="https://foundation.wikimedia.org/wiki/Terms_of_Use">תנאי השימוש</a> לפרטים.</li>
      </ul>
    </div>;
  }

  return <MyDrawer inner={inner} close={LawDrawerStore.close} header={header} />;
})